import { createSlice } from "@reduxjs/toolkit";
import { GlobalStore } from "./types";
import { RootState } from "./store";

export const initialGlobalStore: GlobalStore = {
  user: {
    wallet: "",
    isLoading: false,
  },
};

export const globalSlice = createSlice({
  name: "globalReducer",
  initialState: initialGlobalStore,
  reducers: {
    userLogin: (state, action) => {
      return Object.assign(state, {
        user: { wallet: action.payload.wallet, isLoading: false },
      });
    },
    userLogout: (state) => {
      return { user: { wallet: "", isLoading: false } };
    },
  },
});

export const { userLogin, userLogout } = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user;

export default globalSlice.reducer;
