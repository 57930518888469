import React from 'react';
import './Card.css';
import useProxyContract from '../../hooks/useProxyContract';
import { IPhase } from '../../types/IPhase';
import { fetchPhaseInfo } from '../../api/towers.api';
import { useToast } from '../../contexts/Toast';

interface Props {
  data: IPhase;
  sold: number;
}

const Card: React.FC<Props> = ({ data, sold }) => {
  const [phaseInfo, setPhaseInfo] = React.useState<{
    phase: number;
    minFloor: number;
    maxFloor: number;
    soldTokensOnPhase: number;
    phasePrice: number;
  }>({
    phase: 1,
    minFloor: 0,
    maxFloor: 0,
    soldTokensOnPhase: 0,
    phasePrice: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const { buy: buyContract, getProxyInfo } = useProxyContract();
  const showToast = useToast();

  const updatePhaseInfo = React.useCallback(async () => {
    const { data: phaseResponse } = await fetchPhaseInfo(phaseInfo.phase);
    setPhaseInfo({
      phase: phaseInfo.phase,
      minFloor: phaseResponse.message.minFloor,
      maxFloor: phaseResponse.message.maxFloor,
      soldTokensOnPhase: phaseResponse.message.soldTokensOnPhase,
      phasePrice: phaseResponse.message.phasePrice,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async () => {
      await updatePhaseInfo();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buy = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const proxyInfo = await getProxyInfo();

    if (!proxyInfo.status) {
      setLoading(false);
      showToast({
        message: 'The minting contract is currently disabled',
        type: 'error',
      });
      return;
    }

    await buyContract(phaseInfo.phase);
    await updatePhaseInfo();
    setLoading(false);
  };

  return (
    <div className="card">
      <div className="card-box">
        <div className="card-labels">
          <h5>Mint Information</h5>
          <div className="card-points">
            <div className="card-point">
              <span>Price:</span>
              <span>{phaseInfo.phasePrice} ETH</span>
            </div>
            <div className="card-point">
              <span>Floors:</span>
              <span>
                {phaseInfo.minFloor}-{phaseInfo.maxFloor}
              </span>
            </div>
            <div className="card-point">
              <span>Types:</span>
              <span>{`Standard & Luxury`}</span>
            </div>
            {/*<div className="card-point">*/}
            {/*  <span>Luxury Count:</span>*/}
            {/*  <span>1 Per Floor</span>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="card-buttons">
          {data.type === 'live' && (
            <button className="card-button buy" onClick={buy}>
              Buy
            </button>
          )}

          {data.type === 'soldout' && <button className="card-button ghost">SOLD OUT</button>}

          {data.type === 'coming' && <button className="card-button ghost">Coming</button>}
        </div>
      </div>
    </div>
  );
};

export default Card;
