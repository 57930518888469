import React from 'react';
import ToastComponent from './../components/Toast/Toast';

interface IToastContext {
    message: string;
    type: 'success' | 'error';
}

const ToastContext = React.createContext<React.Dispatch<React.SetStateAction<IToastContext>> | undefined>(undefined);

const Toast: React.FC<{ children: React.ReactElement }> = ({children}) => {
    const [show, setShow] = React.useState(false);
    const [toast, setToast] = React.useState<IToastContext>({
        message: '',
        type: 'success',
    });

    React.useEffect(() => {
        if (toast.message) {
            setShow(true);
            setTimeout(() => {
                setShow(false)
            }, 2500);
        } else {
            setShow(false);
        }
    }, [toast])

    return <ToastContext.Provider value={setToast}>
        {children}
        <ToastComponent
            show={show}
            message={toast.message}
            type={toast.type}
        />
    </ToastContext.Provider>
}


export const useToast = () => {
    const context = React.useContext(ToastContext)
    if (context === undefined) {
        throw new Error('toast not initialized');
    }
    return context;
};

export default Toast;