import React, { useCallback, useEffect, useState } from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider';
import WalletConnect from '@walletconnect/client';
import Web3Modal from 'web3modal';
import * as Ethers from 'ethers';
import Web3 from 'web3';
import { getNetworksRpcs } from '../utils/networks';
import { CHAIN_ID, MAINTANANCEMODE, NETWORK, NETWORKS } from '../utils/configs';
import LocalStorage from '../utils/localStorage';
import { fetchAuth, fetchNonce, refreshAccessToken } from '../api/towers.api';
import { JWT_DECODED, JWT_RESPONSE } from '../api/towers.types';
import jwt_decode from 'jwt-decode';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { selectUser, userLogin, userLogout } from '../store/reducer';
import { useToast } from './Toast';

export interface IWalletProvider {
  publicAddress: string;
  balance: string;
  web3Provider?: Ethers.providers.Web3Provider;
  provider?: any;
  web3?: Web3 | null;
  web3Modal?: Web3Modal | null;
  connect?: () => Promise<void>;
  reset?: () => void;
  getContract?: (address: string, abi: Array<any>) => Ethers.ethers.Contract;
  updateBalance?: () => void;
}

const defaultChainId = Object.keys(NETWORKS)[0];
const chainId = parseInt(CHAIN_ID || defaultChainId);
const rpcUrl = NETWORKS[chainId].rpcUrls[0];
export const providerRpc = new Ethers.providers.JsonRpcProvider(rpcUrl);
const network = NETWORK;
const networkName = NETWORKS[chainId].chainName;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: getNetworksRpcs(),
      network,
      pollingInterval: 5000,
    },
  },
};

export const WalletContext = React.createContext<IWalletProvider>({
  publicAddress: '',
  balance: '0',
});

const WalletProvider: React.FC<{ children: React.ReactElement }> = (props) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [publicAddress, setPublicAddress] = useState<string>('');
  const [balance, setBalance] = useState<string>('0');
  const [web3Provider, setWeb3Provider] = useState<Ethers.providers.Web3Provider | undefined>();
  const [provider, setProvider] = useState<any>(null);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | null>(null);

  const authUserLoading = user.isLoading;
  const authUser = user;

  const connect = useCallback(async () => {
    if (publicAddress || !web3Modal) {
      return;
    }

    try {
      const connectedProvider = await web3Modal.connect();
      const web3 = new Web3(connectedProvider);
      setProvider(connectedProvider);
      setWeb3Provider(new Ethers.providers.Web3Provider(connectedProvider));
      setWeb3(web3);
    } catch (error) {
      console.error(error);
    }
  }, [publicAddress, web3Modal]);

  const getContract = (address: string, abi: Array<any>): Ethers.ethers.Contract => {
    const signer = web3Provider && web3Provider.getSigner();
    return new Ethers.Contract(address, abi, signer || providerRpc);
  };

  useEffect(() => {
    setWeb3Modal(
      new Web3Modal({
        theme: 'dark',
        providerOptions,
        cacheProvider: true,
        disableInjectedProvider: false,
        network,
      })
    );
  }, []);

  const getNoncePhrase = React.useCallback(
    async (wallet: string) => {
      try {
        const { data } = await fetchNonce(wallet);
        return String(data.message);
      } catch (error: any) {
        if (error?.response?.data?.message === 'You are banned') {
          toast({ message: 'You are banned', type: 'error' });
          logout(false);
          return '';
        }
        logout();
        return '';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast]
  );

  const logout = useCallback(
    (reload = true) => {
      const client = new WalletConnect({
        bridge: 'https://bridge.walletconnect.org',
      });
      client.killSession().finally(() => {
        dispatch(userLogout());
        setWeb3Provider(undefined);
        setProvider(null);
        setWeb3(null);
        setPublicAddress('');
        setBalance('0');
        LocalStorage.remove('jwt');
        web3Modal?.clearCachedProvider();
        reload && window.location.reload();
      });
    },
    [dispatch, web3Modal]
  );

  const makeSignature = React.useCallback(async () => {
    try {
      let connectedSigner = web3Provider?.getSigner();
      const nonce: string = await getNoncePhrase(publicAddress);
      if (!nonce) return '';
      const result = await connectedSigner!.signMessage(nonce).catch(() => {
        logout();
      });
      return result;
    } catch (error) {
      logout();
    }
  }, [web3Provider, getNoncePhrase, publicAddress, logout]);

  const signMessage = React.useCallback(async () => {
    if (!publicAddress || !provider) {
      return;
    }
    try {
      const signature = await makeSignature();
      if (signature) {
        const { data } = await fetchAuth({ wallet: publicAddress, signature });
        const jwt = data.message;
        LocalStorage.add<JWT_RESPONSE>('jwt', jwt);
        dispatch(
          userLogin({
            wallet: publicAddress,
          })
        );
      }
    } catch (error) {
      logout();
    }
  }, [dispatch, publicAddress, provider, makeSignature, logout]);

  const login = useCallback(
    async (wallet: string, web3: Web3) => {
      const jwt = LocalStorage.get<JWT_RESPONSE>('jwt');
      try {
        if (!jwt) {
          await signMessage();
        } else {
          const decodedRefresh = jwt_decode<JWT_DECODED>(jwt.refreshToken);
          const current = Math.round(Date.now() / 1000);

          if (decodedRefresh.exp < current || decodedRefresh.wallet !== wallet) {
            await signMessage();
          } else {
            await refreshAccessToken(wallet);
            dispatch(
              userLogin({
                wallet,
              })
            );
          }
        }
      } catch (error) {
        logout();
      }
    },
    [dispatch, signMessage, logout]
  );

  const reset = useCallback(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Modal, logout]);

  useEffect(() => {
    if (!web3Modal?.cachedProvider || MAINTANANCEMODE) {
      return;
    }
    web3Modal.connect().then((connectedProvider) => {
      const web3 = new Web3(connectedProvider);
      setProvider(connectedProvider);
      setWeb3Provider(new Ethers.providers.Web3Provider(connectedProvider));
      setWeb3(web3);
    });
  }, [web3Modal]);

  useEffect(() => {
    if (!web3Provider || !provider || !web3) {
      return;
    }

    web3Provider.getNetwork().then((networkInfo) => {
      if (networkInfo.chainId !== chainId) {
        alert(`Please switch to ${networkName} network`);
        reset();
        return;
      }

      web3.eth.getAccounts().then((account) => {
        setPublicAddress(account[0]);
        login(account[0], web3).catch(() => {
          logout();
        });
      });

      provider?.on('accountsChanged', () => {
        console.log('accountsChanged');
        reset();
      });
      provider.on('connect', (info: { chainId: number }) => {
        console.log('connect');
        if (chainId !== info.chainId) {
          reset();
        }
      });
      provider.on('disconnect', (error: { code: number; message: string }) => {
        console.log('disconnect');
        reset();
        console.log(error.code);
      });
    });
  }, [web3Provider, provider, web3, reset, login, logout]);

  const updateBalance = useCallback(() => {
    if (!publicAddress || !web3Provider || !web3) {
      setBalance('0');
      return;
    }
    const signer = web3Provider && web3Provider.getSigner();
    signer.getBalance().then((data) => {
      const walletAmount = web3 && web3.utils.fromWei(data.toString(), 'ether');
      setBalance(walletAmount || '0');
    });
  }, [publicAddress, web3Provider, web3]);

  useEffect(() => {
    if (!publicAddress) {
      setBalance('0');
      return;
    }
    updateBalance();
  }, [publicAddress, updateBalance]);

  useEffect(() => {
    if (!authUserLoading && !authUser) {
      setPublicAddress('');
      setBalance('0');
    }
  }, [authUserLoading, authUser]);

  return (
    <WalletContext.Provider
      value={{
        publicAddress,
        balance,
        connect,
        reset,
        getContract,
        updateBalance,
        web3,
        web3Modal,
        web3Provider,
      }}
      {...props}
    >
      {props.children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
