import React from 'react';
import './MobileMenu.css';
import computeClasses from '../../utils/computeClasses';
import cutWallet from '../../utils/cutWallet';
import useWalletConnect from '../../hooks/useWalletConnect';
import { useAppSelector } from '../../hooks/store';
import { selectUser } from '../../store/reducer';

interface Props {
  open: boolean;
  onClose: () => void;
}

const MobileMenu: React.FC<Props> = ({ open, onClose }) => {
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const { connect } = useWalletConnect();
  const authUser = useAppSelector(selectUser);

  React.useEffect(() => {
    setOpenMobileMenu(open);
  }, [open]);

  const close = () => {
    setOpenMobileMenu(false);
    onClose();
  };

  const handleConnect = async () => {
    connect && (await connect());
    close();
  };

  return (
    <aside
      className={computeClasses({
        'translate-x-0': openMobileMenu,
        'translate-x-full': !openMobileMenu,
        sidenav: true,
      })}
    >
      <div className="header-mobile">
        <div className="logo-mobile">
          <img src="img/logo.png" alt="Ethereum towers" />
        </div>
        <div className="close-mobile" onClick={close}>
          <img src="img/icons/close.svg" alt="close" />
        </div>
      </div>
      <div className="menu-mobile">
        {!authUser.wallet && (
          <button className="connect" onClick={handleConnect}>
            Connect wallet
          </button>
        )}

        {authUser.wallet && <span>Connected: {cutWallet(authUser.wallet)}</span>}
      </div>
      <ul className="social-mobile">
        <li>
          <a target="_blank" href="https://twitter.com/ethereumtowers" rel="noreferrer">
            <img src="img/icons/twitter.svg" alt="twitter" />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://discord.gg/ethereumworlds" rel="noreferrer">
            <img src="img/icons/discord.svg" alt="discord" />
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default MobileMenu;
